import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';

export default function Menu() {

  const items = [
    {
      src: '/main/20201015_carimage_02.jpg',
      altText: 'SEAT Arona',
      header:'SEAT Arona',
      caption: 'Discover Continental Comfort & Dynamic Driving Performance.'
    },
    {
      src: '/main/20201015_carimage_03.jpg',
      altText: 'Mercedes-Benz E-Class',
      header: 'Mercedes-Benz E-Class',
      caption: 'The most intelligent saloon in the business class.'
    },
    {
      src: '/main/20201015_carimage_04.jpg',
      altText: 'Seat Leon',
      header: 'Seat Leon',
      caption: 'A hatchback compact car '
    },
    {
      src: '/main/20201015_carimage_05.jpg',
      altText: 'Skoda Kodiaq',
      header: 'Skoda Kodiaq',
      caption: 'The beauty of the beast'
    }
  ];

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <img src={item.src} alt={item.altText} class="d-block w-100" />
        <CarouselCaption captionText={item.caption} captionHeader={item.header} />
      </CarouselItem>
    );
  });

  return (
    <Carousel activeIndex={activeIndex} next={next} previous={previous}>
      <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
      {slides}
      <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
      <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
    </Carousel>
  )
}
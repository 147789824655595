import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Menu from "../components/menu"

export default function Home() {
  return (
    <Layout>
      <Menu />
        
      <div class="container">
        <div class="row mx-lg-n5 mt-5">
            <h3>Latest New Cars</h3>
        </div>
        
        <div class="row mx-lg-n5">
            <div class="col-sm-6 col-md-4 p-2">
                <div class="card">
                    <img src="/main/20201015_carimage_06.jpg" class="card-img-top" alt="20201015_carimage_06.jpg" />
                    <div class="card-body">
                    <h5 class="card-title">Skoda Kamiq</h5>
                    <p class="card-text">You’ll instantly be drawn to the unmistakable bold design combining a practical, roomy city car with the advantages of an SUV.</p>
                    <Link to="/carsnew" class="btn btn-danger btn-block">Available Prices</Link>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-4 p-2">
                <div class="card">
                    <img src="/main/20201015_carimage_07.jpg" class="card-img-top" alt="20201015_carimage_07.jpg" />
                    <div class="card-body">
                    <h5 class="card-title">Skoda Scala</h5>
                    <p class="card-text">Remarkable for its versatility and spaciousness, you’ll easily cope with anything the future has in store for you.</p>
                    <Link to="/carsnew" class="btn btn-danger btn-block">Available Prices</Link>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-4 p-2">
                <div class="card">
                    <img src="/main/20201015_carimage_08.jpg" class="card-img-top" alt="20201015_carimage_08.jpg" />
                    <div class="card-body">
                    <h5 class="card-title">Skoda Octavia</h5>
                    <p class="card-text">Presenting the all-new OCTAVIA, a roomy model that, versatile in business situations and practical within the family.</p>
                    <Link to="/carsnew" class="btn btn-danger btn-block">Available Prices</Link>
                    </div>
                </div>
            </div>
          </div>
        
        <div class="row mx-lg-n5 mt-5">
            <h3>Popular Cars</h3>
        </div>
        
        <div class="row mx-lg-n5">
            <div class="col-sm-6 col-md-4 p-2">
                <div class="card">
                    <img src="/main/20201015_carimage_09.jpg" class="card-img-top" alt="20201015_carimage_09" />
                    <div class="card-body">
                    <h5 class="card-title">SEAT Arona</h5>
                    <p class="card-text">The SEAT Arona is the crossover built for the bold. It’s ready for action. Are you?.Do your thing, your way. The SEAT Arona has your back.</p>
                    <Link to="/carsnew" class="btn btn-danger btn-block">Available Prices</Link>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-4 p-2">
                <div class="card">
                    <img src="/main/20201015_carimage_10.jpg" class="card-img-top" alt="20201015_carimage_10" />
                    <div class="card-body">
                    <h5 class="card-title">SEAT Toleda</h5>
                    <p class="card-text">Who said fun is only for kids? The Toledo combines a safe family car with a beautiful, dynamic drive.</p>
                    <Link to="/carsnew" class="btn btn-danger btn-block">Available Prices</Link>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-4 p-2">
                <div class="card">
                    <img src="/main/20201015_carimage_16.jpg" class="card-img-top" alt="20201015_carimage_16" />
                    <div class="card-body">
                    <h5 class="card-title">Mercedes A-Class</h5>
                    <p class="card-text">Just ask it yourself! It is able to speak, comprehend and think, and it has a magically human feel.</p>
                    <Link to="/carsnew" class="btn btn-danger btn-block">Available Prices</Link>
                </div>
            </div>
          </div>
        </div>
        
        <div class="row mx-lg-n5 mt-5">
            <h3>Used Cars Great Savings</h3>
        </div>

        <div class="row mx-lg-n5">
            <div class="col-sm-6 col-md-4 p-2">
                <div class="card">
                    <img src="/main/20201015_carimage_12.jpg" class="card-img-top" alt="20201015_carimage_12" />
                    <div class="card-body">
                    <h5 class="card-title">Skoda Octavia</h5>
                    <p class="card-text">With its compact dimensions, state-of-the-art driver-assistance systems, perfect for city traffic and long hauls alike.</p>
                    <Link to="/carsnew" class="btn btn-danger btn-block">Available Prices</Link>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-4 p-2">
                <div class="card">
                    <img src="/main/20201015_carimage_13.jpg" class="card-img-top" alt="20201015_carimage_13" />
                    <div class="card-body">
                    <h5 class="card-title">Audi A6</h5>
                    <p class="card-text">The Audi A6 is elegant and sporty in design with combination of cutting-edge technology and unmistakable sophistication.</p>
                    <Link to="/carsnew" class="btn btn-danger btn-block">Available Prices</Link>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-4 p-2">
                <div class="card">
                    <img src="/main/20201015_carimage_14.jpg" class="card-img-top" alt="20201015_carimage_14" />
                    <div class="card-body">
                    <h5 class="card-title">Audi Q5</h5>
                    <p class="card-text">Expressive appearance, powerful drive, and pioneering technology. Your options are just as diverse.</p>
                    <Link to="/carsnew" class="btn btn-danger btn-block">Available Prices</Link>
                </div>
            </div>
          </div>
        </div>
        
        <blockquote class="blockquote text-center mx-auto p-5">
            <p class="mb-0">Singapore's Number #1 Car Market Place</p>
            <footer class="blockquote-footer">Cited by Eric Tan Choa Hong <cite title="Source Title">Car Daily SG</cite></footer>
        </blockquote>

      </div>
    </Layout>
  )
}